export enum RequestType {
  GetDom,
}

export interface Request {
  type: RequestType;
}

export const DomRequest: Request = {
  type: RequestType.GetDom,
};

export interface DomResponse {
  document: string;
}

export type Response = DomResponse;
