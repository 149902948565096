import * as ChromeUtil from "./util";
import { ContentScript, ServiceWorker } from "../types";

export const setLoggedIn = async (loggedIn: Boolean) => {
  return await ChromeUtil.set("loggedIn", loggedIn.toString());
};

export const getLoggedIn = async () => {
  const val = await ChromeUtil.get("loggedIn");
  return val === true || val === "true";
};

export const isDevelopment = async () => {
  if (chrome.management === undefined) return true;
  const self = await chrome.management.getSelf();
  return self.installType === "development";
};

export const createSessionWindow = async (sessionId: number, windowId: number) => {
  const baseUrl = window.location.href.startsWith("chrome-extension://")
    ? window.location.href
    : window.location.origin;
  const nextUrl = `${baseUrl}?sessionId=${sessionId}&windowId=${windowId}`;
  window.open(
    nextUrl,
    "_blank",
    `popup,
        width=500,
        height=${window.screen.availHeight},
        top=0,
        left=${window.screen.availWidth - 500}`
  );
};

export const getCurrentWindowId = async (): Promise<number | undefined> => {
  if (chrome.windows === undefined) return -1;
  const window = await chrome.windows.getCurrent();
  return window.id;
};

export const getActiveTabDetails = async (): Promise<[string, string]> => {
  if (chrome.tabs === undefined) return ["https://www.example.com", window.location.href];

  const [tab] = await chrome.tabs.query({
    active: true,
    currentWindow: true,
  });

  return [tab?.url || "", tab?.title || ""];
};

// hits service-worker
export const initLogin = async (callback: (redirectUrl?: string) => Promise<void>) => {
  if (await isDevelopment())
    return callback(
      `#id_token={"email": "test@mail.com","given_name":"Test","family_name":"User"}`
    );
  return chrome.runtime.sendMessage(ServiceWorker.AuthRequest, callback);
};

// hits content-script
export const getDom = async (windowId: number, sessionUrlRaw: string): Promise<string> => {
  if (chrome.tabs === undefined) return `<body></body>`;
  const [tab] = await chrome.tabs.query({
    active: true,
    windowId: windowId,
  });

  if (!tab || !tab.id || tab.url == undefined) {
    console.error("Data missing: no active tab ID or URL found.");
    return "";
  }

  const tabUrl = new URL(tab.url);
  const sessionUrl = new URL(sessionUrlRaw);
  if (tabUrl.hostname !== sessionUrl.hostname) {
    console.error("Active tab URL does not match session URL");
    return "";
  }

  const response: any = await chrome.tabs.sendMessage(tab.id || 0, ContentScript.DomRequest);

  return response.document;
};
