export enum RequestType {
  InitiateAuth,
}

export interface Request {
  type: RequestType;
}

export const AuthRequest: Request = {
  type: RequestType.InitiateAuth,
};

export interface Response {}
