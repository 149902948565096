import * as ChromeClient from "../chrome";

enum HttpMethod {
  POST = "POST",
  GET = "GET",
}

const serverUrl = async () => {
  try {
    if (!(await ChromeClient.isDevelopment())) return "https://try-machina.com";
  } finally {
    return "http://localhost:3001";
  }
};

const httpCall = async <T>(route: string, method: HttpMethod, body?: Object): Promise<T | null> => {
  const headers = new Headers({ "Content-Type": "application/json" });
  const baseUrl = await serverUrl();
  try {
    const response = await fetch(baseUrl + route, {
      credentials: "include",
      method: method,
      headers: headers,
      body: JSON.stringify(body),
    });

    let responseBody = {};
    try {
      responseBody = await response.json();
    } catch {}

    if (!response.ok)
      throw new Error(`${response.status} ${response.statusText} ${JSON.stringify(responseBody)}`);

    return responseBody as T;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const get = async <T>(route: string) => {
  return await httpCall<T>(route, HttpMethod.GET);
};

export const post = async <T>(route: string, body: Object) => {
  return await httpCall<T>(route, HttpMethod.POST, body);
};
