export enum Language {
  Java = "Java",
  Python3 = "Python 3",
  Cpp = "C++",
}

export namespace UserMessageContent {
  export type AutoProblemStatement = {
    dom: string;
  };

  export type UserEntryProblemStatement = {
    text: string;
  };

  export type FreeText = {
    text: string;
    language: Language;
  };

  export type GeneralApproach = {
    language: Language;
  };

  export type Pseudocode = {
    language: Language;
  };

  export type Solution = {
    language: Language;
  };
}

export namespace ServiceMessageContent {
  export type ProblemAck = {
    parsedProblem: string;
  };

  export type QueryResponse = {
    text: string;
  };

  export type ClarifyProblem = {};
}
