export const set = async (key: string, val: any): Promise<void> => {
  if (chrome.storage === undefined) {
    return localStorage.setItem(key, JSON.stringify(val));
  } else return await chrome.storage.local.set({ [key]: val });
};

export const get = async <T>(key: string): Promise<T | null> => {
  if (chrome.storage === undefined) {
    const getResult = localStorage.getItem(key);
    return getResult === null ? null : (JSON.parse(getResult) as T);
  } else {
    const getResult = await chrome.storage.local.get(key);
    const res = getResult[key];
    return res === undefined ? null : (res as T);
  }
};
