import { UserMessageContent, ServiceMessageContent } from "./message-content";

export type Errable = {
  errorMessage?: string;
};

export type AuthRequest = {
  jwt: string;
};

export type BillingInitResponse = {
  url: string;
};

export type UserContextResponse = {
  firstName: string;
  canCreateSession: boolean;
  isSubscriber: boolean;
  openSessionId?: number;
};

export enum MessageSource {
  User = "user",
  Service = "service",
}

export enum UserMessageAction {
  FreeText = 0,
  AutoProblemStatement = 1,
  UserEntryProblemStatement = 2,
  Pseudocode = 3,
  Solution = 4,
  GeneralApproach = 5,
}

export enum ServiceMessageAction {
  QueryResponse = 0,
  ClarifyProblem = 1,
  ProblemAck = 2,
}

// prettier-ignore
export type UserContent =
  | { action: UserMessageAction.FreeText; content: UserMessageContent.FreeText }
  | { action: UserMessageAction.GeneralApproach; content: UserMessageContent.GeneralApproach }
  | { action: UserMessageAction.Pseudocode; content: UserMessageContent.Pseudocode }
  | { action: UserMessageAction.Solution; content: UserMessageContent.Solution }
  | { action: UserMessageAction.AutoProblemStatement; content: UserMessageContent.AutoProblemStatement }
  | { action: UserMessageAction.UserEntryProblemStatement; content: UserMessageContent.UserEntryProblemStatement };

// prettier-ignore
export type ServiceContent =
  | {action: ServiceMessageAction.ClarifyProblem, content: ServiceMessageContent.ClarifyProblem}
  | {action: ServiceMessageAction.ProblemAck, content: ServiceMessageContent.ProblemAck}
  | {action: ServiceMessageAction.QueryResponse, content: ServiceMessageContent.QueryResponse};

export interface Message {
  createdAt: number;
  source: MessageSource;
}

export type UserMessage = Message & UserContent;

export type ServiceMessage = Message & ServiceContent;

export type CreateSessionResponse = Errable & {
  sessionId: number;
};

export type FetchSessionResponse = {
  createdAt: number;
  completedAt: number | null;
  url: string;
  messages: Message[];
};
