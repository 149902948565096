import * as ApiClient from "../api";
import * as ChromeClient from "./client";
import * as Types from "../types";

const launchChromeAuth = () => {
  const ClientId = "233345763838-tjsq5n2g1c9ii183m81vvvpoj7bsuupl.apps.googleusercontent.com";
  const RedirectUri = `https://${chrome.runtime.id}.chromiumapp.org/`;
  const nonce = Math.random().toString(36).substring(2, 15);

  const authUrl = new URL("https://accounts.google.com/o/oauth2/v2/auth");
  authUrl.searchParams.set("client_id", ClientId);
  authUrl.searchParams.set("response_type", "id_token");
  authUrl.searchParams.set("redirect_uri", RedirectUri);
  // Add the OpenID scope. Scopes allow you to access the user’s information.
  authUrl.searchParams.set("scope", "openid profile email");
  authUrl.searchParams.set("nonce", nonce);
  // Show the consent screen after login.
  authUrl.searchParams.set("prompt", "consent");

  const onLogin = async (redirectUrl?: string) => {
    if (redirectUrl) {
      const urlHash = redirectUrl.split("#")[1];
      const params = new URLSearchParams(urlHash);
      const jwt = params.get("id_token") || "";
      try {
        await ApiClient.User.login(jwt);
        await ChromeClient.setLoggedIn(true);
      } catch {}
    }
  };

  chrome.identity.launchWebAuthFlow({ url: authUrl.href, interactive: true }, onLogin);
};

const requestHandler = (
  req: Types.ServiceWorker.Request,
  _sender: chrome.runtime.MessageSender,
  _sendResponse: (res: Types.ServiceWorker.Response) => void,
): boolean => {
  switch (req.type) {
    case Types.ServiceWorker.RequestType.InitiateAuth:
      launchChromeAuth();
      break;
    default:
      return false;
  }

  return true;
};

chrome.runtime.onMessage.addListener(requestHandler);
