import { Api } from "../types";
import * as Http from "./helpers";

export const login = async (jwt: string) => {
  const body: Api.AuthRequest = { jwt: jwt };
  return await Http.post(`/api/user`, body);
};

export const logout = async () => {
  return await Http.get("/api/user/logout");
};

export const fetchContext = async () => {
  return await Http.get<Api.UserContextResponse>("/api/user");
};
